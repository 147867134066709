import {
    AddressFieldsFragment,
    AddressFragment,
    BuildingFieldsFragment,
    BuildingFragment,
    CallFieldsFragment,
    CallFragment,
    ContactEmailFieldsFragment,
    ContactEmailFragment,
    ContactFieldsFragment,
    ContactFragment,
    ContactPhoneFieldsFragment,
    ContactPhoneFragment,
    DealContactFieldsFragment,
    DealContactFragment,
    DealFieldsFragment,
    DealFragment,
    DevelopmentFieldsFragment,
    DevelopmentFragment,
    EmployeeFieldsFragment,
    EmployeeFragment,
    EmployeeVirtualPhoneFieldsFragment,
    EmployeeVirtualPhoneFragment,
    EstateFieldsFragment,
    EstateFragment,
    FragmentType,
    getFragment,
    LeadFieldsFragment,
    LeadFragment,
    LeadResultFieldsFragment,
    LeadResultFragment,
    ProjectFeatureFieldsFragment,
    ProjectFeatureFragment,
    PropertyCurrencyConversionFieldsFragment,
    PropertyCurrencyConversionFragment,
    PropertyDetailsFieldsFragment,
    PropertyDetailsFragment,
    PropertyFieldsFragment,
    PropertyFiltersFieldsFragment,
    PropertyFiltersFragment,
    PropertyFragment,
    PropertyImageFieldsFragment,
    PropertyImageFragment,
    ResponsibleFieldsFragment,
    ResponsibleFragment,
    TaskFieldsFragment,
    TaskFragment,
    VoiceBotConversationFieldsFragment,
    VoiceBotConversationFragment,
    WhatsappAccountFieldsFragment,
    WhatsappAccountFragment,
} from '@sputnikestate/graph';

export const useFragments = () => {
    const getDealFragment = (fragment?: FragmentType<DealFieldsFragment> | null) => {
        const data = getFragment(DealFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getPropertyFragment = (fragment?: FragmentType<PropertyFieldsFragment> | null) => {
        const data = getFragment(PropertyFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getPropertyDetailsFragment = (fragment?: FragmentType<PropertyDetailsFieldsFragment> | null) => {
        const data = getFragment(PropertyDetailsFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getPropertyImageFragment = (fragment?: FragmentType<PropertyImageFieldsFragment> | null) => {
        const data = getFragment(PropertyImageFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getDevelopmentFragment = (fragment?: FragmentType<DevelopmentFieldsFragment> | null) => {
        const data = getFragment(DevelopmentFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getCommunityFragment = (fragment?: FragmentType<EstateFieldsFragment> | null) => {
        const data = getFragment(EstateFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getAddressFragment = (fragment?: FragmentType<AddressFieldsFragment> | null) => {
        const data = getFragment(AddressFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getBuildingFragment = (fragment?: FragmentType<BuildingFieldsFragment> | null) => {
        const data = getFragment(BuildingFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getContactFragment = (fragment?: FragmentType<ContactFieldsFragment> | null) => {
        const data = getFragment(ContactFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getContactPhoneFragment = (fragment?: FragmentType<ContactPhoneFieldsFragment> | null) => {
        const data = getFragment(ContactPhoneFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getContactEmailFragment = (fragment?: FragmentType<ContactEmailFieldsFragment> | null) => {
        const data = getFragment(ContactEmailFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getLeadFragment = (fragment?: FragmentType<LeadFieldsFragment> | null) => {
        const data = getFragment(LeadFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getDealContactFragment = (fragment?: FragmentType<DealContactFieldsFragment> | null) => {
        const data = getFragment(DealContactFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getResponsibleFragment = (fragment?: FragmentType<ResponsibleFieldsFragment> | null) => {
        const data = getFragment(ResponsibleFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getTaskFragment = (fragment?: FragmentType<TaskFieldsFragment> | null) => {
        const data = getFragment(TaskFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getEmployeeFragment = (fragment?: FragmentType<EmployeeFieldsFragment> | null) => {
        const data = getFragment(EmployeeFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getPropertyFiltersFragment = (fragment?: FragmentType<PropertyFiltersFieldsFragment> | null) => {
        const data = getFragment(PropertyFiltersFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getCallFragment = (fragment?: FragmentType<CallFieldsFragment> | null) => {
        const data = getFragment(CallFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getEmployeeVirtualPhoneFragment = (fragment?: FragmentType<EmployeeVirtualPhoneFieldsFragment> | null) => {
        const data = getFragment(EmployeeVirtualPhoneFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getPropertyCurrencyConversionFragment = (fragment?: FragmentType<PropertyCurrencyConversionFieldsFragment> | null) => {
        const data = getFragment(PropertyCurrencyConversionFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getLeadResultFragment = (fragment?: FragmentType<LeadResultFieldsFragment> | null) => {
        const data = getFragment(LeadResultFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getWhatsappAccountFragment = (fragment?: FragmentType<WhatsappAccountFieldsFragment> | null) => {
        const data = getFragment(WhatsappAccountFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getProjectFeatureFragment = (fragment?: FragmentType<ProjectFeatureFieldsFragment> | null) => {
        const data = getFragment(ProjectFeatureFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    const getVoiceBotConversationFragment = (fragment?: FragmentType<VoiceBotConversationFieldsFragment> | null) => {
        const data = getFragment(VoiceBotConversationFragment, fragment);

        if (data) {
            return data;
        }
        return null;
    };

    return {
        getDealFragment,
        getPropertyFragment,
        getPropertyDetailsFragment,
        getDevelopmentFragment,
        getCommunityFragment,
        getAddressFragment,
        getBuildingFragment,
        getContactFragment,
        getLeadFragment,
        getDealContactFragment,
        getResponsibleFragment,
        getTaskFragment,
        getContactPhoneFragment,
        getContactEmailFragment,
        getEmployeeFragment,
        getPropertyImageFragment,
        getPropertyFiltersFragment,
        getCallFragment,
        getEmployeeVirtualPhoneFragment,
        getPropertyCurrencyConversionFragment,
        getLeadResultFragment,
        getWhatsappAccountFragment,
        getProjectFeatureFragment,
        getVoiceBotConversationFragment,
    };
};
