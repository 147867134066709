import {create} from "zustand";
import {
    AddressFieldsFragment,
    ContactFieldsFragment,
    DealContactFieldsFragment,
    DealFieldsFragment,
    DevelopmentFieldsFragment,
    EstateFieldsFragment,
    FragmentType,
    LeadFieldsFragment,
    PropertyFieldsFragment,
    TaskFieldsFragment
} from "@sputnikestate/graph";
import {PropertyUpdaterType} from "../views/Properties/Property/Property/components/Updater";
import {DealUpdaterType} from "../views/deals/Deal/components/Updater";
import {TaskUpdaterType} from "../views/tasks/Task/components/Updater";
import {LeadUpdaterType} from "../views/leads/Lead/components/Updater";

type EditorType =
    'property__contact__create' |
    'deal__contact__create' |
    'property__contact__update' |
    'deal__contact__update' |
    'contact__update' |
    'contact__phone__create' |
    'contact__email__create' |
    'property__update' |
    'deal__update' |
    'task__update' |
    'lead__update';

type StateBase = {
    containerClassName?: string;
    propertyUpdaterType?: PropertyUpdaterType;
    dealUpdaterType?: DealUpdaterType;
    taskUpdaterType?: TaskUpdaterType;
    leadUpdaterType?: LeadUpdaterType;
    dealFragment?: FragmentType<DealFieldsFragment>;
    propertyFragment?: FragmentType<PropertyFieldsFragment>;
    contactFragment?: FragmentType<ContactFieldsFragment>;
    dealContactFragment?: FragmentType<DealContactFieldsFragment>;
    taskFragment?: FragmentType<TaskFieldsFragment>;
    leadFragment?: FragmentType<LeadFieldsFragment>;
    payload?: {
        addresses?: FragmentType<AddressFieldsFragment>[] | null;
        communities?: FragmentType<EstateFieldsFragment>[] | null;
        developments?: FragmentType<DevelopmentFieldsFragment>[] | null;
    };
}

type OpenEditorProps = {
    type: EditorType;
    title: string;
    description: string;
} & StateBase;

type State = {
    type?: EditorType;
    title?: string;
    description?: string;
} & StateBase;

type Actions = {
    openEditor: (props: OpenEditorProps) => void;
    closeEditor: () => void;
};

const initial: State = {
    type: undefined,
    title: undefined,
    description: undefined,
    containerClassName: undefined,
    propertyUpdaterType: undefined,
    dealUpdaterType: undefined,
    taskUpdaterType: undefined,
    leadUpdaterType: undefined,
    dealFragment: undefined,
    propertyFragment: undefined,
    contactFragment: undefined,
    dealContactFragment: undefined,
    taskFragment: undefined,
    leadFragment: undefined,
    payload: undefined,
};

export const useEditorStore = create<State & Actions>()((set) => ({
    ...initial,
    openEditor: (props) => {
        set(props);
    },
    closeEditor: () => {
        set(initial);
    },
}));
