import * as React from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {handleResponseError, useGeneratePassword, useValidatePassword} from "@sputnikestate/graph";
import PinField from "react-pin-field";
import {Controller} from "react-hook-form";
import toast from "react-hot-toast";
import InputError from "../../../../../primitives/Fields/Common/InputError";
import PrimaryButton from "../../../../../primitives/Buttons/Primary";
import {twMerge} from "tailwind-merge";
import {useLoginStore} from "@sputnikestate/store";
import {IForm} from "../Form";
import {useAnalytics, useRouter} from "../../../../../../hooks";

interface IValidate extends IForm {
    phone?: string;
}

const Validate: React.FC<IValidate> = (
    {
        phone,
        titleClassName,
        descriptionClassName,
        validateTitle,
        validateDescription,
        formContainerClassName,
        validateHelpText,
        onValidateSuccess,
    }
) => {
    const [formError, setFormError] = React.useState<{
        [key: string]: any
    }>({});
    const [generateDisabled, setGenerateDisabled] = useState(true);
    const [timer, setTimer] = useState(60);
    const {t} = useTranslation(['common', 'pages', 'general']);
    const pinFieldRef = React.useRef<HTMLInputElement[]>(null);
    const analytics = useAnalytics();
    const {query} = useRouter();

    const title = React.useMemo(
        () => {
            if (validateTitle === null) return null;
            if (validateTitle) return validateTitle;
            return t('login.validate.title', {ns: 'general'});
        },
        [validateTitle],
    );

    const description = React.useMemo(
        () => {
            if (validateDescription === null) return null;
            if (validateDescription) return validateDescription;
            return t('login.validate.description', {ns: 'general'});
        },
        [validateDescription],
    );

    const onSuccessStore = useLoginStore((state) => state.onSuccess);

    const onSuccess = () => {
        if (onValidateSuccess) onValidateSuccess();
        else if (onSuccessStore) onSuccessStore();
        analytics.track('Login OTP Validated');
    };

    React.useEffect(() => {
        if (pinFieldRef.current) {
            pinFieldRef.current[0].focus();
        }
    }, [pinFieldRef.current]);

    React.useEffect(() => {
        if (formError.detail?.message) {
            toast.error(formError.detail.message);
            setFormError(errors => {
                delete errors.detail.message;
                return errors;
            })
        }
        if (formError.nonFieldErrors?.message) {
            toast.error(formError.nonFieldErrors.message);
            setFormError(errors => {
                delete errors.nonFieldErrors.message;
                return errors;
            })
        }
    }, [formError]);

    React.useEffect(
        () => {
            const interval = setInterval(() => {
                if (timer <= 1) {
                    setGenerateDisabled(false);
                    setTimer(0);
                    clearInterval(interval)
                }
                if (timer > 1) {
                    setTimer(t => t - 1);
                }
            }, 1000);
            return () => clearInterval(interval);
        },
        [timer]
    );

    const onError = () => {
        if (timer === 0) setGenerateDisabled(false);
    };

    const {
        control,
        onSubmit,
        clearErrors,
        setValue,
        formState: {errors}
    } = useValidatePassword({
        onSuccess,
        onError,
        setFormError,
    });

    React.useEffect(
        () => {
            if ('sct' in query) setValue('contactId', query.sct);
        },
        [query]
    );

    const onGeneratePasswordSuccess = () => {
        setGenerateDisabled(true);
        toast.success(t('login.validate.newCodeSent', {ns: 'general'}));
        clearErrors();
        if (pinFieldRef.current) {
            pinFieldRef.current.forEach(input => input.value = '');
            pinFieldRef.current[0].focus();
        }
        setTimer(60);
    };

    const onGeneratePasswordError = (e: any) => {
        setGenerateDisabled(false);
        handleResponseError(e, null, setFormError);
    };

    const {generatePassword, isLoading} = useGeneratePassword({
        onSuccess: onGeneratePasswordSuccess,
    });

    const onValidatePassword = () => {
        setGenerateDisabled(true);
        void onSubmit();
    };

    const onGeneratePassword = async () => {
        if (phone) {
            try {
                await generatePassword({phone});
            } catch (e) {
                onGeneratePasswordError(e);
            }
        }
    };

    return (
        <div className="flex flex-col items-center justify-center w-full min-h-[inherit]">
            <div className="md:max-w-md mx-auto w-full">
                {title && (
                    <h1
                        className={twMerge(
                            'text-slate-900 font-black text-4xl sm:text-5xl tracking-tight text-center',
                            titleClassName,
                        )}
                    >
                        {title}
                    </h1>
                )}
                {description && (
                    <div
                        className={twMerge(
                            'text-center font-medium text-slate-600 text-base sm:text-xl mt-4 md:mt-6',
                            descriptionClassName,
                        )}
                    >
                        {description}
                    </div>
                )}
                <div className={twMerge('flex flex-col items-center mt-10', formContainerClassName)}>
                    <div className="flex justify-center">
                        <Controller
                            control={control}
                            name={'otp'}
                            render={({field: {onChange}}) => (
                                <PinField
                                    ref={pinFieldRef}
                                    length={4}
                                    inputMode="numeric"
                                    className={twMerge(
                                        'h-16 w-16 bg-cultured-100 hover:bg-blue-25 border border-cultured-100 hover:border-blue-25 focus-within:bg-blue-25 focus-within:border-blue-25 rounded-lg text-2xl text-cultured-700 font-semibold text-center mr-2.5 last:mr-0 shadow-none outline-0 focus:outline-none focus:ring-0 transition-colors',
                                        errors.otp && 'bg-red-100 border-red-100 hover:bg-red-100 hover:border-red-100 focus-within:bg-red-100 focus-within:border-red-100'
                                    )}
                                    onChange={onChange}
                                    onComplete={onValidatePassword}
                                />
                            )}
                        />
                    </div>
                    {errors.otp && <InputError>{errors.otp.message}</InputError>}
                    {!errors.otp && validateHelpText && (
                        <div className="text-xs text-slate-900 mt-1.5">
                            {validateHelpText}
                        </div>
                    )}
                </div>
                <PrimaryButton
                    disabled={isLoading || generateDisabled}
                    loading={isLoading}
                    onClick={onGeneratePassword}
                    title={
                        <>
                            {generateDisabled && timer >= 1 && t('login.validate.resendCode', {
                                ns: 'general',
                                seconds: timer >= 10 ? timer : `0${timer}`
                            })}
                            {(timer === 0 || !generateDisabled) && t('login.validate.getCodeAgain', {ns: 'general'})}
                        </>
                    }
                    className={twMerge(
                        'h-14 lg:h-16 lg:text-lg mt-7',
                        generateDisabled && 'bg-white hover:bg-white text-slate-600'
                    )}
                />
            </div>
        </div>
    );
};

export default Validate;
